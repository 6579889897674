import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useCustomTheme } from "../../assets/themes/themes";

const pages = [
  { label: "Home", href: "#home" },
  { label: "BootCamp Program", href: "#bootcamp-program" },
  { label: "About this Course", href: "#about-this-course" },
  { label: "Project Opportunities", href: "#project-opportunities" },
  { label: "How it Works", href: "#how-it-works" },
  { label: "Contact Us", href: "#contact-us" },
  { label: "Join Now", href: "https://academy-portal-web.vercel.app/" },
];

function NavBarCP() {
  const { isMobile } = useCustomTheme();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "transparent", boxShadow: "none" }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontWeight: 700,
              letterSpacing: "1.5",
              color: "#000",
              textDecoration: "none",
              fontSize: isMobile ? 13 : 20,
              fontFamily: "Poppins, sans-serif",
            }}
          >
            NexGen Coders Program
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="default"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              {pages.map((page: any) => (
                <MenuItem key={page.label} onClick={handleCloseNavMenu}>
                  <Typography
                    component="a"
                    color="black"
                    href={page.href}
                    fontSize={isMobile ? 13 : 20}
                    textAlign="center"
                    fontFamily="Poppins, sans-serif"
                    style={{ textDecoration: "none" }}
                  >
                    {page.label}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "Poppins, sans-serif",
              fontWeight: 700,
              color: "#000",
              textDecoration: "none",
              fontSize: isMobile ? 16 : 20,
            }}
          >
            NextGen Coders Program
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyItems: "flex-end",
              alignItems: "flex-end",
            }}
          ></Box>

          <Box sx={{ flexGrow: 0 }}>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyItems: "flex-end",
                alignItems: "flex-end",
              }}
            >
              {pages.map((page: any) => (
                <Button
                  key={page.label}
                  href={page.href} // Fixed: Use href directly here
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: "#000", display: "block" }}
                >
                  {page.label}
                </Button>
              ))}
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default NavBarCP;
