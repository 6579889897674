import { Box, Button, Grid, Typography } from "@mui/material";
import NavBarCP from "../../components/navbar/Navbar";
import HeroImg from "../../assets/images/hero.png";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useCustomTheme } from "../../assets/themes/themes";
import { motion } from "framer-motion";
import { styled } from "@mui/system";

const MotionTypography = styled(motion(Typography))``;
const MotionButton = styled(motion(Button))``;

function Home() {
  const { COLORS, isMobile, isTablet } = useCustomTheme();

  const handleJoinNowClick = () => {
    window.location.href = "https://academy-portal-web.vercel.app/";
    // const contactSection = document.getElementById("contact-us");
    // if (contactSection) {
    //   contactSection.scrollIntoView({ behavior: "smooth" });
    // }
  };

  const handleHowItsWorkClick = () => {
    const workSection = document.getElementById("how-it-works");
    if (workSection) {
      workSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Box sx={{ backgroundColor: COLORS.CREAM, p: 2 }}>
      <motion.div
        initial={{ y: -90, opacity: 0 }}
        animate={{
          y: 0.2,
          opacity: 1,
          transition: { delay: 0.3, duration: 2 },
        }}
      >
        <NavBarCP />
      </motion.div>
      <Grid mt={isMobile ? 2 : 0} container>
        <Grid
          item
          xs={12}
          md={6}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          sm={12}
        >
          <Box>
            <MotionTypography
              color={COLORS.PURPLE}
              textAlign={isMobile ? "center" : "inherit"}
              fontSize={isMobile ? 20 : isTablet ? 25 : 50}
              fontFamily="Poppins, sans-serif"
              initial={{ x: 200, opacity: 0 }}
              animate={{ x: 1, opacity: 1 }}
              transition={{ duration: 2, delay: 0.2 }}
            >
              <span style={{ color: COLORS.ORANGE }}>NexGen Coders</span>{" "}
              Program to Tech Mastery
            </MotionTypography>
            <MotionTypography
              color={COLORS.LIGHT_BLACK}
              mt={2}
              mb={2}
              fontSize={isMobile ? 13 : 25}
              textAlign={isMobile ? "center" : "inherit"}
              fontFamily="Poppins, sans-serif"
              initial={{ x: -500, opacity: 0.5 }}
              animate={{ x: 0, opacity: 2 }}
              transition={{ delay: 0.2, duration: 2 }}
            >
              Master web, Android, and iOS development with our comprehensive
              BootCamp Program, designed for success in today's digital
              landscape.
            </MotionTypography>

            <Box
              display={"flex"}
              flexDirection={isMobile ? "column" : "row"}
              mt={isMobile ? 3 : 2}
              justifyContent={isMobile ? "center" : "inherit"}
              alignItems={"center"}
            >
              <MotionButton
                initial={{ y: 100, opacity: 0.2 }}
                animate={{
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 3,
                  },
                }}
                sx={{
                  background: COLORS.ORANGE,
                  color: COLORS.WHITE,
                  borderRadius: isMobile ? 10 : 80,
                  width: isMobile ? 150 : 220,
                  height: isMobile ? 30 : 50,
                  fontSize: isMobile ? 12 : 18,
                  fontFamily: "Poppins, sans-serif",
                }}
                onClick={handleJoinNowClick}
              >
                Join Now
              </MotionButton>
              <Box
                display={"flex"}
                mt={isMobile ? 5 : 0}
                alignItems={"center"}
                ml={isMobile ? 1 : 2}
              >
                <motion.div
                  initial={{ x: 100, opacity: 0.1 }}
                  animate={{
                    x: 0,
                    opacity: 0.9,
                    transition: {
                      delay: 0.2,
                      duration: 2.5,
                    },
                  }}
                  style={{
                    background: COLORS.WHITE,
                    borderRadius: 99,
                    width: isMobile ? 40 : 60,
                    height: isMobile ? 40 : 60,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="watch"
                  onClick={handleHowItsWorkClick}
                >
                  <PlayArrowIcon
                    style={{
                      fontSize: isMobile ? 20 : 20,
                      color: COLORS.LIGHT_BLUE,
                    }}
                  />
                </motion.div>
                <MotionTypography
                  color={COLORS.LIGHT_BLACK}
                  ml={isMobile ? 2 : 2}
                  fontSize={isMobile ? 16 : 18}
                  fontFamily="Poppins, sans-serif"
                  initial={{ y: 100, opacity: 0.1 }}
                  animate={{
                    y: 0.2,
                    opacity: 1,
                    transition: { delay: 0.2, duration: 3 },
                  }}
                >
                  Watch How it Works
                </MotionTypography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid xs={12} md={6} item display={"flex"} justifyContent={"center"}>
          <motion.img
            initial={{ x: 50, opacity: 0 }}
            animate={{
              x: 0,
              opacity: 1,
              transition: { delay: 0.5, duration: 3 },
            }}
            src={HeroImg}
            alt="hero"
            style={{
              width: isMobile ? 300 : isTablet ? "100%" : "100%",
              height: isMobile ? 320 : 700,
              objectFit: "contain",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Home;
